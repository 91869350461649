import React from 'react';
import AdvancedAccordion from './Accordion';

const ServicesContent: React.FC = () => {
  // Define your accordion items data
  const accordionItems = [
    {
      title: "Salons and Appointments",
      content: [
        { question: "Book an Appointment:", 
        answerText: "You can <a href='/booking'>Tap Here to Book Online,</a> or your can browse our Services and Prices below, tapping the name of the Service you're interested in to Book that Service Online." 
        },
        { question: "Salons and Pricing:", answerText: "You can see the Locations, Opening Hours, Full Service Menus and Prices of each Masters Location <a href='/our-locations'>Here.</a>" },
        { question: "Our Team:", answerText: "All Masters Stylists and Beauticians are here to help you look and feel your very best. You can see our Team <a href='/team'>Here.</a>" },
        {
          question: "Gifts, Occasions, and Custom Packages:",
          answerText: "Treat someone to an Masters Appointment with an <a href='/gift-cards'>eGift Card.</a> - purchased online, emailed instantly and available to use immediately. If you need further assistance, or would like us to arrange a special occasion or custom appointment package, please <a href='/contact-us'>Contact Us</a>, where we can make the arrangements for you.", // No link provided for this item
        },
      ],
    },
    {
        title: "Free Consultations",
        content: [
          { question: "Free Consultations:", 
          answerText: "At Masters, we believe in making our environment and your visit as comfortable, rewarding and stress-free as possible. That's why we offer Free Consultations for all services, allowing you to pop in for a free 15min chat about anything we do to get help, advice, or to just put yourself at ease before your actual appointment." 
          }
        ],
      },
      {
        title: "Hair",
        content: [
          { question: "Haircuts & Styling:", 
          answerText: "Wether you're looking for full re-style, a trim or anything inbetween, Masters is here to help. We can offer a full Wash, Cut & Blow-Dry, a Dry Cut, Hair-Ups for special occasions or even just your hair curling, we can offer something for every day and every budget." 
          },
          { question: "Hair Colour:", 
          answerText: "All Masters Hair Colour services are tailored towards your exact needs. To suit all requirements and budgets, we offer a variety of Hair Colour techniques (such as Balayage, Highlights, Grey Coverage and more) plus a variety of finishing services (ranging from Haircuts with full styling, or just Blast-Offs for people who want colour without the fuss). All colour services are also available with or without Olaplex." 
          },
          { question: "Hair Extensions:", 
          answerText: "Following a Free Hair Extensions Consultation (where your Masters Stylist will look at the current length, thickness and condition of your hair, and discuss your inspiration and desired result), visit us for a set of premium-quality, expertly fitted Hair Extensions you can trust." 
          },
          { question: "Keratin Anti-Frizz Hair Smoothing Treatment:", 
          answerText: "Masters' Keratin Smoothing Treatment is an evolution in straightening and repair. This semi-permanent treatment has been designed to transform even the most difficult hair into stronger, smoother, repaired hair that's easy to maintain and gorgeous to the touch. The unique combination of low pH proteins will renew dry, damaged and weak hair, transforming it completely into healthy, smoother and stronger hair that both looks and feels amazing. These incredible repairing agents rebuild the hair with added strength, vitality and shine providing a smooth and soft finish that lasts for up to 3 months, gradually fading from the hair." 
          },
          { question: "Olaplex Treatments:", 
          answerText: "Enjoyed as a stand-alone treatment with a Cut & Blow-Dry or Blow-Dry, or as part of any Hair Colour service, Olaplex makes your hair stronger, healthier, and makes colour last longer by re-linking broken bonds on a molecular level to create strength, structure and integrity, acting as a 'Reset Button' for the condition of your hair." 
          },
          { question: "Men's Hair:", 
          answerText: "Masters offers a selection of Men's Haircut services (with the option to have your hair washed or not), along with an exclusive menu of Men's Hair Colour services (such as Men's Highlights and Grey Hair Blending)." 
          },
        ],
      },
      {
        title: "FACIALS",
        content: [
          { question: "Facials", 
          answerText: "Crystal Clear Facials (currently available at Masters Leeds (Queens Arcade) only), use techniques such as Microdermabrasion, Microneedling and Oxygen Therapy to make skin smoother, clearer and firmer. All Masters Salons offer both Grown Alchemist and Personalised Facials, using the highest quality skincare availabe to alleviate a variety of skin concerns including stress, dehydration, discolouration, imperfections and toxins to revive your complexion." 
          }
        ],
      },
      {
        title: "MASSAGE",
        content: [
          { question: "Massage", 
          answerText: "Our expert Massage Therapists can help you to decide which Massage is best for you from our comprehensive Massage menu. Wether you need a Massage for muscle relief, relaxation, pregnancy or to help you sleep, Masters has the perfect Massage experience for you." 
          }
        ],
      },
      {
        title: "BODY TREATMENTS",
        content: [
          { question: "Body Treatments:", 
          answerText: "Crystal Clear Body Treatments (currently available at Masters Leeds (Queens Arcade) only) applied using a machine, intensively treat the skin to make it smoother, firmer, brighter, softer, clearer and more refined. All Masters Locations offer slimming and Contouring Body Treatments with an intensive Thermogenic action, for profound and resistant cellulite and imperfections. This treatment helps to improve the typical signs of cellulite, improves microcirculation, and thanks to the stimulation of lipolysis, promotes the slimming, contouring and remodelling of the body." 
          }
        ],
      },
      {
        title: "LYCON WAXING",
        content: [
          { question: "Lycon Waxing", 
          answerText: "Suitable for Ladies and Men (excluding Intimate Waxing). We offer both Hot (stripless) Wax, and Warm (strip) Wax. Reserved for 5* Spas, Hotels, Resorts and World-Class Salons, Lycon is formulated with the finest natural resins and bees wax, allowing superior results and the removal of even the most stubborn hair as short as 1mm." 
          }
        ],
      },
      {
        title: "MANICURES AND PEDICURES",
        content: [
          { question: "Gel Colour:", 
          answerText: "Enjoy high-shine, smudge-proof, chip resistant nail colour for up to 14 days with The Manicure Company Gel Colour Polish." 
          },
          { question: "Callus Peel Pedicure:", 
          answerText: "Suitable for Ladies and Men. Our Callus Peel Pedicure is a Chemical Peel for your feet, smoothing and removing even the toughest calluses, making the skin on your feet clear, smooth and renewed." 
          }
        ],
      },
      {
        title: "BROWS AND LASHES",
        content: [
          { question: "Shaping and Tinting:", 
          answerText: "Enjoy perfectly shaped and tinted Brows and Lashes, performed by our expertly trained Beauticians." 
          },
          { question: "Eyebrow Lamination:", 
          answerText: "Eyebrow Lamination delivers fuller-looking, straightened, tamed, brows for up to 6 weeks. Including an Eyebrow Shape and Tint, this service is perfect for anyone who would like to fill gaps in their brows, redefine and correct their shape, and create a fuller, more defined, lifted brow. Suitable for Ladies and Men." 
          },
          { question: "Eyelash Lift:", 
          answerText: "Increase the natural volume, lift and curl of your Eyelashes for up to 8 weeks with an Eyelash Lift, which can be combined with an Eyelash Tint for an even more noticeable effect." 
          }
        ],
      },
      
    // Add more accordion items as needed
  ];

  return (
    <div>
      {/* Use the AdvancedAccordion component and pass the accordion items */}
      <AdvancedAccordion accordionItems={accordionItems} />
    </div>
  );
};

export default ServicesContent;
