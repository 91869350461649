import React from 'react';
import Typography from '@mui/material/Typography';

interface HeadingProps {
  text: string; // Define the type for the 'text' prop
}

const Heading: React.FC<HeadingProps> = ({ text }) => {
  return (
    <Typography
      variant="body1"
      sx={{
        fontFamily: "'Futura', sans-serif", // Use Futura font with sans-serif fallback
        fontWeight: 200, // Example font weight
        fontSize: '12px', // Example font size
        color: '#c2c1c1', // Example text color
        letterSpacing: '.2em', // Letter spacing
        textTransform: 'uppercase',
        marginBottom: '10px',
      }}
    >
      {text}
    </Typography>
  );
};

export default Heading;
