import React from 'react';
import Link from '@mui/material/Link';

interface NavigationlinkProps {
  to: string;
  primaryText: string;
  disabled?: boolean; // Optional prop for disabled link
}

const Navigationlink: React.FC<NavigationlinkProps> = ({ to, primaryText, disabled = false }) => {
  return (
    <Link
      href={disabled ? undefined : to} // Disable link if disabled prop is true
      underline={disabled ? "none" : "hover"} 
    style={{ 
      fontFamily: "'Futura', sans-serif", 
      color: '#8d8f8f',
      transition: 'all .2s ease-in-out', 
      lineHeight: 1.5, 
      fontSize: '13px', 
      fontWeight: '200' 
      }}>
      {primaryText}
    </Link>
  );
};

export default Navigationlink;
