import React from "react";
import sec2 from "../images/landing.jpg";
import DynamicSpace from "../../styles/Helpers/DynamicSpace";
import GlobalHeading from "../../styles/components/GlobalHeading";
import GlobalParagraph from "../../styles/components/GlobalParagraph";
import { Button, Link, Typography } from "@mui/material";

const FaqsLanding: React.FC = () => {
  return (
    <>
      <div className="div0">
        <img
          src={sec2}
          alt=""
          className="accordion_body_image"
          style={{
            display: "block",
            float: "left",
            margin: "0 auto",
            maxWidth: "80%",
            maxHeight: "400px",
          }}
        />
      </div>
      <DynamicSpace gs={30} />

      <GlobalHeading text='FREQUENTLY ASKED QUESTIONS' fontSize={{ xs: '20px', md: '20px' }} />

      <DynamicSpace gs={35} />
      <GlobalParagraph
        fontSize={{ xs: '14px', md: '14px' }}
        lineHeight='1.65'
        letterSpacing="0.02em"
        text="Allertons is here to make you feel good. We offer all of our services in a comfortable, non-judgemental environment where our team are tasked with using their expertise and training to give you the result and experience you need. 
        Sometimes, you will have questions before you visit, and we are here to answer them for you, to make your visit even more comfortable and stress-free."
      />
      <DynamicSpace gs={35} />
      <div className="button-container" style={{ display: 'flex' }}>
        <Link href="/booking">
          <Button
            variant="contained"
            className="servicePageButton"
            style={{ marginRight: '10px', backgroundColor: 'black' }}
          >
            <Typography className='landingButtonsTypography' sx={{color: '#ffffff'}} variant="body1">
              Book Online
            </Typography>
          </Button>
        </Link>
        <Link href="/contact-us">
          <Button
            variant="contained"
            className="servicePageButton"
            style={{ marginRight: '10px', backgroundColor: 'black' }}
          >
            <Typography className='landingButtonsTypography' sx={{color: '#ffffff'}} variant="body1" >
              Contact Us
            </Typography>
          </Button>
        </Link>
      </div>

      <DynamicSpace gs={35} />
    </>
  );
};
export default FaqsLanding;
