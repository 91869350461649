import React from 'react';
import Typography from '@mui/material/Typography';

interface HeadingProps {
  text: string;
  className?: string; // Make className prop optional
}

const Heading2: React.FC<HeadingProps> = ({ text, className }) => {
  return (
    <Typography
      variant="h1"
      sx={{
        fontSize: {
          xs: '18px', // For extra-small screens and above
          md: '22px', // For medium screens and above
        },
        letterSpacing: '0.2em',
        fontWeight: 400,
        fontFamily: 'Futura, sans-serif',
        lineHeight: {
          xs: '1.3', // Apply line height for extra-small screens only
        },
      }}
      className={className} // Assign the className prop to the Typography component
    >
      {text}
    </Typography>
  );
};

export default Heading2;
