import React from 'react';
import landingImage from '../images/landing.jpg'; // Import the image file
import { Button, Typography, ThemeProvider, createTheme } from '@mui/material'; // Import Button, Typography, ThemeProvider, and createTheme components from Material-UI
import SecondSection from './secondsection';
import '../../styles/styles.css';
import Heading2 from '../../styles/components/Heading2';
import Heading6 from '../../styles/components/Heading6';
import DynamicSpace from '../../styles/Helpers/DynamicSpace';
import ThirdSection from './thirdsection';
import FourthSection from './fourthsection';
import FifthSection from './fifthsection';
import SixthSection from './sixthsection';
import { Link } from 'react-router-dom';

const LandingSection: React.FC = () => {
  return (
    <>
    <section   className='section-back landing-section'   style={{ backgroundImage: `linear-gradient(rgb(0 0 0 / 39%) 0%, rgb(0 0 0 / 58%) 100%), url(${landingImage})`}} >
      <div style={{ position: 'relative', zIndex: 1, textAlign: 'center', color: 'white' }}>
        <Heading6 text="Welcome to" />
        <DynamicSpace gs={16} sm={12} md={15} />
        <Heading2 text="MASTER'S HAIR & BEAUTY" className="heading-mobile" />
        <div className="button-container" style={{ display: 'flex', justifyContent: 'center', marginTop: '35px' }}>
          <Link to="/booking" style={{ textDecoration: 'none' }}>
            <Button variant="contained" color="primary" className="leftButton">
              <Typography className='landingButtonsTypography' variant="body1">
                Book Online
              </Typography>
            </Button>
          </Link>
          <Link to="/our-locations" style={{ textDecoration: 'none' }}>
          <Button
            variant="contained"
            className="righButton"
            color="secondary"
          >
            <Typography className='landingButtonsTypography' variant="body1" >Our Location</Typography>
          </Button>
          </Link>
        </div>
      </div>
    </section>
    <SecondSection />
    <ThirdSection />
    <FourthSection />
    <FifthSection/>
    <SixthSection />
    </>
     
  );
};

export default LandingSection;
