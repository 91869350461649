import team from '../images/section22.jpg'; // Import the image file
import '../../styles/styles.css'; // Import global CSS file
import GlobalHeading from '../../styles/components/GlobalHeading';
import GlobalParagraph from '../../styles/components/GlobalParagraph';
import DynamicSpace from '../../styles/Helpers/DynamicSpace';
import GlobalAnchor from '../../styles/components/GlobalAnchor';

const FourthSection = () => {
  return (
    <section className='section-back content-section' style={{
        backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.26) 0%, rgba(0, 0, 0, 0.32) 95%), url(${team})`
      }}>
        <div className="content-card">
          <GlobalHeading text="OUR TEAM" variant="h2" fontSize={{ xs: '12px', md: '12px' }} />
          <DynamicSpace gs={14} />
          <GlobalParagraph
            text="We are people who take care of people. Visiting Master's is about much more than the Services you're booking - it's about you. Our Team are here to take care of you, not just to provide a service."
            fontSize={{ xs: '12px', md: '14px' }}
            lineHeight="1.65"
            letterSpacing="0.06em"
            color="#1c1b1b"
          />
          <DynamicSpace gs={14} />
          <GlobalAnchor text="See Our Team Members" href="#" />
        </div>
    </section>
  );
};

export default FourthSection;
