import sec333 from '../images/landing.jpg'; // Import the image file
import '../../styles/styles.css'; // Import global CSS file
import GlobalHeading from '../../styles/components/GlobalHeading';
import GlobalParagraph from '../../styles/components/GlobalParagraph';
import DynamicSpace from '../../styles/Helpers/DynamicSpace';
import GlobalAnchor from '../../styles/components/GlobalAnchor';

const ThirdSection = () => {
  return (
    <section className='section-back content-section' style={{
      backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.26) 0%, rgba(0, 0, 0, 0.32) 95%), url(${sec333})`, // Apply linear gradient overlay
      }}>
      <div className="content-card" >
        <GlobalHeading text="OUR SERVICES" variant="h2" fontSize={{ xs: '12px', md: '12px' }} />
        <DynamicSpace gs={14} />
        <GlobalParagraph
          text="A tailor-made service, just for you. Master's Hairstylists use their training and expertise to give you the result and experience you're looking for. From Hair Colour to other Services, Master's offers something for everyone."
          fontSize={{ xs: '12px', md: '14px' }}
          lineHeight="1.65"
          letterSpacing="0.06em"
          color="#1c1b1b"
        />
        <DynamicSpace gs={14} />
        <GlobalAnchor text="See Our Services" href="/services" />
      </div>
    </section>
  );
};

export default ThirdSection;
