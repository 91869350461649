import React from 'react';
import Typography, { TypographyProps } from '@mui/material/Typography';

interface ParagraphProps extends TypographyProps {
  text: string;
  className?: string;
  fontSize?: { xs?: string; sm?: string; md?: string; lg?: string; xl?: string } | undefined;
  lineHeight?: string | undefined;
  letterSpacing?: string | undefined;
  color?: string | undefined;
}

const GlobalParagraph: React.FC<ParagraphProps> = ({
  text,
  className,
  fontSize,
  lineHeight,
  letterSpacing,
  color,
  ...otherProps
}) => {
  const styles = {
    fontFamily: "'Futura', sans-serif", // Default font family
    ...(fontSize && { fontSize }),
    ...(lineHeight && { lineHeight }),
    ...(letterSpacing && { letterSpacing }),
    ...(color && { color }),
  };

  return (
    <Typography variant="body1" sx={styles} className={className} {...otherProps}>
      {text}
    </Typography>
  );
};

export default GlobalParagraph;