import React from 'react';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import StarBorderIcon from '@mui/icons-material/StarBorder';
import GlobalHeading from '../../styles/components/GlobalHeading';
import DynamicSpace from '../../styles/Helpers/DynamicSpace';
import DynamicDivider from '../../styles/Helpers/DynamicDivider';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import ChatIcon from '@mui/icons-material/Chat';

const AboutUs: React.FC = () => {
  return (
    <>
      <GlobalHeading
        text="About Us"
        variant="h3"
        fontSize={{ xs: "14px", md: "16px" }}
        fontWeight={600}
        letterSpacing="0.04em"
      />
      <DynamicSpace gs={10} />
      <Box sx={{ display: 'flex', flexDirection: 'column',  boxShadow: 'rgba(99, 99, 99, 0.2) 0px 2px 8px 0px', borderRadius: "10px",  }}>
        {/* First Icon Section */}
        <DynamicSpace gs={5} />

        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            borderRadius: '10px',
            padding: '20px 10px',
          }}
        >
          {/* Icon */}
          <IconButton>
            <LocationOnIcon sx={{color: '#337ab7'}} />
          </IconButton>

          {/* Content */}
          <div>
            <GlobalHeading
              text="Find Us"
              color={"#337ab7"}
              variant="h3"
              fontSize={{ xs: "11px", md: "13px" }}
              fontWeight={400}
              letterSpacing="0.04em"
            />
            <DynamicSpace gs={5} />
            <GlobalHeading
              text="180 Seven Sisters Road, Finsbury Park, London N7 7PX"
              variant="h3"
              fontSize={{ xs: "12px", md: "14px" }}
              fontWeight={600}
              letterSpacing="0.04em"
            />
            <DynamicSpace gs={5} />
          </div>
        </Box>
        <DynamicDivider length="95%" position="center" color="#7bb2e612" />



        {/* Second Icon Section */}
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            gap: '10px',
            borderRadius: '10px',
            padding: '20px 10px',
          }}
        >
          {/* Icon */}
          <IconButton>
            <LocalPhoneIcon sx={{color: '#337ab7'}} />
          </IconButton>

          {/* Content */}
          <div>
            <GlobalHeading
              text="Call Us"
              color={"#337ab7"}
              variant="h3"
              fontSize={{ xs: "11px", md: "13px" }}
              fontWeight={400}
              letterSpacing="0.04em"
            />
            <DynamicSpace gs={5} />
            <GlobalHeading
              text="020 7916 1502"
              variant="h3"
              fontSize={{ xs: "12px", md: "14px" }}
              fontWeight={600}
              letterSpacing="0.04em"
            />
            <DynamicSpace gs={5} />
          </div>
        </Box>

        <DynamicDivider length="95%" position="center" color="#7bb2e612" />


        {/* Third Icon Section */}
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            gap: '10px',
            borderRadius: '10px',
            padding: '20px 10px',
          }}
        >
          {/* Icon */}
          <IconButton>
            <ChatIcon sx={{color: '#337ab7'}} />
          </IconButton>

          {/* Content */}
          <div>
            <GlobalHeading
              text="Email Us"
              variant="h3"
              color={"#337ab7"}
              fontSize={{ xs: "11px", md: "13px" }}
              fontWeight={400}
              letterSpacing="0.04em"
            />
            <DynamicSpace gs={5} />
            <GlobalHeading
              text="Section 1"
              variant="h3"
              fontSize={{ xs: "12px", md: "14px" }}
              fontWeight={600}
              letterSpacing="0.04em"
            />
            <DynamicSpace gs={5} />
          </div>
        </Box>
        <DynamicSpace gs={5} />

      </Box>
    </>
  );
};

export default AboutUs;
