import React from 'react';
import { Container, Typography, Box, Button } from '@mui/material';
import { Link } from 'react-router-dom';

const NotFoundPage: React.FC = () => {
  return (
    <Container maxWidth="md" sx={{ my: 15 }}>
      <Box sx={{ textAlign: 'center' }}>
        <Typography variant="h1" gutterBottom>
          404
        </Typography>
        <Typography variant="h5" gutterBottom>
          Page Not Found
        </Typography>
        <Typography variant="body1" gutterBottom>
          The page you're looking for doesn't exist.
        </Typography>
        <Button
          sx={{backgroundColor: '#76a1b1' , mt: 2}}
          variant="contained"
          color="primary"
          component={Link}
          to="/"
        >
          Go to Home
        </Button>
      </Box>
    </Container>
  );
};

export default NotFoundPage;