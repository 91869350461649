import React, { useState, useRef, MouseEvent } from "react";
import DOMPurify from 'dompurify';
import DynamicSpace from "../../styles/Helpers/DynamicSpace";
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import Os_landing from "./Os_landing";

interface AccordionItem {
  title: string;
  content: {
    question: string;
    answerText: string;
    link?: string;
  }[];
}

interface AccordionProps {
  accordionItems: AccordionItem[];
}

const LeftSidebar: React.FC<{ accordionItems: AccordionItem[] }> = ({ accordionItems }) => {
  const handleQuestionClick = (index: number) => (e: MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();
    const section = document.getElementById(`section-${index}`);
    if (section) {
      section.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  };

  return (
    <div className="accordion_widget" style={{ position: "sticky", top: 0, width: "250px", height: "100vh" }}>
      <ul style={{ listStyle: "none", margin: 0, padding: 0 }}>
        {accordionItems.map((item, index) => (
          <li key={index}>
            <a
              href={`#section-${index}`}
              onClick={handleQuestionClick(index)}
              className="accordion_widget_title"
              style={{
                textDecoration: "none",
                cursor: "pointer",
                display: "block",
                color: "black",
              }}
            >
              {item.title}
            </a>
          </li>
        ))}
      </ul>
    </div>
  );
};

const AdvancedAccordion: React.FC<AccordionProps> = ({ accordionItems }) => {
  const [expandedQuestion, setExpandedQuestion] = useState<string | null>(null);
  const contentRefs = useRef<{ [key: string]: HTMLLIElement | null }>({});

  const handleQuestionChange = (question: string) => () => {
    setExpandedQuestion((prevExpandedQuestion) =>
      prevExpandedQuestion === question ? null : question
    );
  };

  return (
    <>
      <DynamicSpace gs={50} />
      <div className="services_content" style={{ display: "flex", minHeight: "100vh" }}>
        <LeftSidebar accordionItems={accordionItems} />
        
        <div className="accordion_body" style={{ flex: 1, display: 'grid', marginLeft: '25px' }}>
        <Os_landing />

          <div style={{ position: "relative" }}>
            {accordionItems.map((item, index) => (
              <li
                key={index}
                id={`section-${index}`}
                ref={(el) => (contentRefs.current[item.title] = el)}
                style={{ marginBottom: "20px", listStyle: "none" }}
              >
                <div className="accordion_title">{item.title}</div>
                {item.content.map((contentItem, contentIndex) => (
                  <div key={`${index}-${contentIndex}`}>
                    <div
                      onClick={handleQuestionChange(contentItem.question)}
                      style={{ cursor: "pointer" }}
                      className="accordion_sub_title"
                    >
                      {/* Prefixed icons to accordion subtitles */}
                      {expandedQuestion === contentItem.question ? <KeyboardArrowDownIcon sx={{ fontSize: '12px', marginRight:'10px' }} /> : <NavigateNextIcon sx={{ fontSize: '12px', marginRight:'10px' }} />}
                      {contentItem.question}
                    </div>
                    <div
                      style={{
                        maxHeight: expandedQuestion === contentItem.question ? `${contentRefs.current[item.title]?.scrollHeight}px` : "0",
                        overflow: "hidden",
                        transition: "max-height 0.3s cubic-bezier(0.42, 0, 0.58, 1)",
                      }}
                    >
                      {/* Sanitize the HTML content and set class */}
                      <div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(`<p class="accordion_sub-description">${contentItem.answerText}</p>`, { ADD_TAGS: ['a'], ADD_ATTR: ['href'] }) }} />
                    </div>
                  </div>
                ))}
              </li>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default AdvancedAccordion;
