import { Grid, Typography } from '@mui/material';
import card00 from '../images/card00.jpg'; // Import the image file
import '../../styles/styles.css'; // Import global CSS file
import GlobalHeading from '../../styles/components/GlobalHeading';
import DynamicSpace from '../../styles/Helpers/DynamicSpace';
import Heading2 from '../../styles/components/Heading2';
import GlobalParagraph from '../../styles/components/GlobalParagraph';
import GlobalAnchor from '../../styles/components/GlobalAnchor';


const FifthSection = () => {
  return (
    <section className="section-back section-fifth" >
      <Grid container spacing={3} justifyContent="center" alignItems="center">
        <Grid item xs={12} sm={6}>
            <div style={{  maxWidth: '520px', padding: '48px 15px 48px 0px'}}>
                <GlobalHeading text="OUR SERVICES" variant="h2" fontSize={{ xs: '12px', md: '12px' }} />
                <DynamicSpace gs={25} />
                <Heading2 text="MASTER'S GIFT CARDS" />
                <DynamicSpace gs={25} />
                <GlobalParagraph
                text="Delivered Instantly via email to you or someone else, Master's eGift Cards can be used for any In-Salon Product or Service at any Master's Location. The perfect treat for any occasion."
                fontSize={{ xs: '13px', md: '14px' }}
                lineHeight="1.65"
                letterSpacing="0.005em"
                color="#454545"
                />
                <DynamicSpace gs={14} />
                <GlobalAnchor  text="Buy Online Now"  textAlign="left"  href="#" />
            </div>
        </Grid>
        <Grid item xs={12} sm={6} className="fourthImage" style={{ 
          maxWidth: '100%', // Adjusted the max width
          maxHeight:'100%',
          height: '400px', // Set the height
          overflow: 'hidden', // Hide overflow content
        }}>
          <div style={{ 
            backgroundImage: `url(${card00})`,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            width: '97%',
            height: '100%',
          }} />
        </Grid>
      </Grid>
    </section>
  );
};

export default FifthSection;
