import sec2 from '../images/section22.jpg'; // Import the image file
import '../../styles/styles.css'; // Import global CSS file
import GlobalHeading from '../../styles/components/GlobalHeading';
import GlobalParagraph from '../../styles/components/GlobalParagraph';
import DynamicSpace from '../../styles/Helpers/DynamicSpace';
import GlobalAnchor from '../../styles/components/GlobalAnchor';

const SecondSection = () => {
  return (
    <section className='section-back content-section' style={{
          backgroundImage: `linear-gradient(rgb(0 0 0 / 23%) 0%, rgb(0 0 0 / 37%) 100%), url(${sec2})` 
        }}>
        <div className="content-card">
        <GlobalHeading text="OUR LOCATION" variant="h2" fontSize={{ xs: '12px', md: '12px' }} />
        <DynamicSpace gs={14} />
        <GlobalParagraph
          text="Master's Hair & Beauty Salons can be found in 180 Seven Sisters Road, Finsbury Park, London N7 7PX. Our location offers a full range hair, face and body treatments services tailored to you."
          fontSize={{ xs: '12px', md: '14px' }}
          lineHeight="1.6"
          letterSpacing="0.05em"
          color="#555"
        />
        <DynamicSpace gs={14} />
        <GlobalAnchor  text="See Our Location"  href="/our-locations" />
        </div>
    </section>
  );
};

export default SecondSection;
