import React from 'react';
import { Link } from 'react-router-dom';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { List, ListItem, ListItemText } from '@mui/material';
import '../styles/footer.css'; // Import the CSS file
import payment from './payment.png'; // Import the image file
import Heading from '../styles/components/heading';
import Navigationlink from '../styles/components/navigationlink';

const Footer: React.FC = () => {
  return (
    <>
    <div className="footer-container"> {/* Container for the footer */}
      <Grid container spacing={2}>
        <Grid item xs={12} sm={4}>
          <div style={{ display: 'flex', flexDirection: 'column', gap: '15px' }}>
            <Heading text="LOCATIONS & APPOINTMENTS" />
            <Navigationlink to="/Booking" primaryText="Book Online" />
            <Navigationlink to="/our-locations" primaryText="Our Location" />
            <Navigationlink to="/services" primaryText="Our Services" />
            <Navigationlink to="/gift-cards" primaryText="Gift Cards" />
            <Navigationlink to="/salonpolicies" primaryText="Salon Policies" />
          </div>
        </Grid>
        <Grid item xs={12} sm={4}>
          <div style={{ display: 'flex', flexDirection: 'column', gap: '15px' }}>
            <Heading text="HELP AND INFORMATION" />
            <Navigationlink to="/contact-us" primaryText="Contact Us" />
            <Navigationlink to="/faqs" primaryText="FAQs" />
            <Navigationlink to="/about" primaryText="Hair Model Application" />
          </div>
        </Grid>
        <Grid item xs={12} sm={4}>
          <div style={{ display: 'flex', flexDirection: 'column', gap: '15px' }}>
            <Heading text="MASTER'S HAIR & BEAUTY SALONS" />
            <Navigationlink to="/about" primaryText="Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. " disabled={true} />
          </div>
        </Grid>
      </Grid>
    </div>
    <div className="footer-copyright">
    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', gap: '15px' }}>
      <Grid item xs={12} sm={6}>
        <p style={{color: '#ffffffc7', fontFamily: 'Futura, sans-serif', fontSize:'12px' }}>© MASTER'S HAIR & BEAUTY</p>
      </Grid>
      <Grid item xs={12} sm={6}>
        <img src={payment} style={{ width: '200px' }} alt="Payment Methods" />
      </Grid>
    </div>
    </div></>
  );
};

export default Footer;
