import React, { useEffect } from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { useDispatch } from 'react-redux';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import DynamicSpace from '../../styles/Helpers/DynamicSpace';

const defaultTheme = createTheme();

const SignIn: React.FC = () => {
  const navigate = useNavigate();
  

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
  
    const formData = new FormData(event.currentTarget);
    const username = formData.get('email') as string;
    const password = formData.get('password') as string;
  
    try {
      const response = await axios.post('https://ya-civilization-abstracts-caring.trycloudflare.com/login', { username, password });
      const token = response.data.token;
      // Save token to local storage or Redux store
      localStorage.setItem('token', token);
      // Redirect to the desired page after successful login
      // navigate('/dashboard');
      console.log('logged in');
    } catch (error) {
      console.error('Login failed:', error);
      // Handle login failure, show error message, etc.
    }
  };

  const dispatch = useDispatch();

  useEffect(() => {
    const verifyToken = async () => {
      const token = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VySWQiOjQsImlhdCI6MTcxMDM5NTMzMywiZXhwIjoxNzEwMzk1NDMzfQ.5rTZWsP6dpz9R7IrDy8IAg8TXgkwfXStZ11YK5xsgbc'; // Replace with your actual JWT token

      axios.post('https://ya-civilization-abstracts-caring.trycloudflare.com/verifyToken', { token })
        .then(response => {
          // Handle success
          console.log(response.data);
        })
        .catch(error => {
          // Handle error
          if (error.response && error.response.data && error.response.data.error === 'expired') {
            console.log('Token expired'); // Print 'Token expired' if the token is expired
            // navigate('/');
            // Redirect to homepage or any other route
          } else {
            console.error(error); // Print the full error object if it's not an expired token error
          }
        });
    };

    verifyToken();
  }, [dispatch]);

  return (
    <ThemeProvider theme={defaultTheme}>
       <Container component="main"
      maxWidth="xs"
      sx={{height:'90vh',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center'
            }}>
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: '#468bbf' }}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Sign in
          </Typography>
          <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
            <TextField
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email Address"
              name="email"
              autoComplete="email"
              autoFocus
            />
            <TextField
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              type="password"
              id="password"
              autoComplete="current-password"
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 , backgroundColor:"#468bbf",}}
            >
              Sign In
            </Button>
            <Grid container>
              <Grid item xs>
                <Link href="#" variant="body2" style={{color:'#468bbf'}}>
                  Forgot password?
                </Link>
              </Grid>
              <Grid item>
                <Link href="#" variant="body2" style={{color:'#468bbf'}}>
                  {"Don't have an account? Sign Up"}
                </Link>
              </Grid>
            </Grid>
          </Box>
        </Box>
        <DynamicSpace gs={50} />
      </Container>
      <DynamicSpace gs={50} />
    </ThemeProvider>
  );
}
export default SignIn;