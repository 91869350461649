import React from 'react';
import GlobalHeading from '../styles/components/GlobalHeading';
import GlobalParagraph from '../styles/components/GlobalParagraph';
import { Accordion, Container } from '@mui/material';
import DynamicSpace from '../styles/Helpers/DynamicSpace';
import ContactForm from './contactform';
import AdvancedAccordion from './Ourservices/Accordion';

const ContactUs: React.FC = () => {
  return (
    <section className='section-back'>
      <Container>
        <div className='content_panel'>
          <DynamicSpace gs={75} xs={50} sm={50} />
          <GlobalHeading className='typography-center' text='CONTACT US' />
          <DynamicSpace gs={30} />
          <GlobalParagraph
            fontSize={{ xs: '12px', md: '13px' }}
            letterSpacing="0.02em"
            lineHeight='1.65'
            text="All Masters enquiries are handled by our Central Client Concierge team, which allows us to answer any questions you have about Services, Appointments, Products, Online Purchases or to give you Advice as quickly and efficiently as possible."
          />
          <DynamicSpace gs={40} />
          <GlobalHeading text='BOOK ONLINE' />
          <DynamicSpace gs={20} />
          <GlobalParagraph
            fontSize={{ xs: '12px', md: '13px' }}
            letterSpacing="0.02em"
            lineHeight='1.65'
            text="If you would like to book an appointment at one of our Salons, the quickest and easiest way is to Book Online."
          />
          <DynamicSpace gs={40} />
          <GlobalHeading text='TELEPHONE' />
          <DynamicSpace gs={20} />

          <ul className='policies_links'>
            <li>
              London: 020 7916 1502
            </li>
            </ul>

          <DynamicSpace gs={20} />

            <GlobalParagraph
            fontSize={{ xs: '12px', md: '13px' }}
            letterSpacing="0.02em"
            lineHeight='1.65'
            text="Quick and efficient, the Masters Central Client Concierge team are available for your call during the following hours:"
            />

          <DynamicSpace gs={20} />

          <ul className='policies_links'>
            <li>
              Monday 9:30am until 6pm
            </li>
            <li>
              Tuesday 9:30am until 6pm
            </li>
            <li>
              Wednesday 9:30am until 8pm
            </li>
            <li>
              Thursday 9:30am until 8pm
            </li>
            <li>
              Friday 8:30am until 6pm
            </li>
            <li>
              Saturday 8:30am until 6pm
            </li>
            <li>
              Sunday 11:00am until 5pm
            </li>
          </ul>
          <DynamicSpace gs={40} />
          <GlobalHeading text='EMAIL' />
          <DynamicSpace gs={20} />
          <GlobalParagraph
            fontSize={{ xs: '12px', md: '13px' }}
            letterSpacing="0.02em"
            lineHeight='1.65'
            text="You can email your questions to our Central Client Concierge team at support@mastersonline.com. Due to how quickly appointment availability changes, we cannot accept bookings, amendments or cancellations via Email, we instead invite you to Book Online."
          />
          <DynamicSpace gs={40} />
          <GlobalHeading text='SOCIAL MEDIA' />
          <DynamicSpace gs={20} />
          <GlobalParagraph
            fontSize={{ xs: '12px', md: '13px' }}
            letterSpacing="0.02em"
            lineHeight='1.65'
            text="Messages to our Facebook and Twitter accounts are answered directly by our Central Client Concierge team. We are also on Instagram, but replies to Instagram messages may take longer. Due to how quickly appointment availability changes, we cannot accept bookings, amendments or cancellations via Social Media Messages, we instead invite you to Book Online."
          />
          <DynamicSpace gs={40} />
          <GlobalHeading text='ONLINE FORM' />
          <DynamicSpace gs={20} />
          <GlobalParagraph
            fontSize={{ xs: '12px', md: '13px' }}
            letterSpacing="0.02em"
            lineHeight='1.65'
            text="You can also send an online message directly to our Central Client Concierge team by using the form below:"
          />
          <DynamicSpace gs={35} />
          <ContactForm/>
          <DynamicSpace gs={95} xs={60} sm={60} />
        </div>
      </Container>
      {/* <AdvancedAccordion/> */}
    </section>
  );
};

export default ContactUs;
