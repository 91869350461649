import React from "react";
import sec2 from "../images/landing.jpg";
import DynamicSpace from "../../styles/Helpers/DynamicSpace";
import GlobalHeading from "../../styles/components/GlobalHeading";
import GlobalParagraph from "../../styles/components/GlobalParagraph";
import { Button, Link, Typography } from "@mui/material";

const OsLanding: React.FC = () => {
  return (
    <>
      <div className="div0">
        <img
          src={sec2}
          className="accordion_body_image"
          alt=""
          style={{
            display: "block",
            float: "left",
            margin: "0 auto",
            maxWidth: "80%",
            maxHeight: "400px",
          }}
        />
      </div>
      <DynamicSpace gs={30} />

      <GlobalHeading text='OUR SERVICES' fontSize={{ xs: '20px', md: '20px' }} />

      <DynamicSpace gs={35} />
      <GlobalParagraph
        fontSize={{ xs: '14px', md: '14px' }}
        lineHeight='1.65'
        letterSpacing="0.02em"
        text="With our comprehensive Treatment Menus, Masters is here to make you look and feel your very best. Book Online using the button below, or see the full details, full service menus and pricing of each Masters location Here."
      />
      <DynamicSpace gs={35} />
      <div className="button-container" style={{ display: 'flex' }}>
        <Link href="/booking">
          <Button
            variant="contained"
            className="servicePageButton"
            style={{ marginRight: '10px', backgroundColor: 'black' }}
          >
            <Typography className='landingButtonsTypography' sx={{color: '#ffffff'}} variant="body1">
              Book Online
            </Typography>
          </Button>
        </Link>
        <Link href="/gift-cards">
          <Button
            variant="contained"
            className="servicePageButton"
            style={{ marginRight: '10px', backgroundColor: 'black' }}
          >
            <Typography className='landingButtonsTypography' sx={{color: '#ffffff'}} variant="body1" >
              Gift Cards
            </Typography>
          </Button>
        </Link>
      </div>

      <DynamicSpace gs={35} />
    </>
  );
};
export default OsLanding;
