import React from 'react';
import Typography from '@mui/material/Typography';

interface Heading6props {
  text: string;
  className?: string; // Make className prop optional
}

const Heading6: React.FC<Heading6props> = ({ text, className }) => {
  return (
    <Typography
      variant="h6" // Use h6 variant for the subheading
      sx={{
        fontSize: '13px', // Set the font size to 13px
        letterSpacing: '0.2em', // Set the letter spacing
        fontWeight: 400, // Set the font weight to 400
        fontFamily: 'Futura, sans-serif', // Use the same font family
      }}
      className={className} // Assign the className prop to the Typography component
    >
      {text}
    </Typography>
  );
};

export default Heading6;
