import React from 'react';
import FiberSmartRecordIcon from '@mui/icons-material/FiberSmartRecord';
import GlobalHeading from '../styles/components/GlobalHeading';
import GlobalParagraph from '../styles/components/GlobalParagraph';
import GlobalAnchor from '../styles/components/GlobalAnchor';
import { Container } from '@mui/material';
import DynamicSpace from '../styles/Helpers/DynamicSpace';
import DynamicDivider from '../styles/Helpers/DynamicDivider';

const OurLocations: React.FC = () => {
  return (
    <section className='section-back' >
      <Container>
        <div className='content_panel'>
          <DynamicSpace gs={105} xs={50} sm={95}  />
          <GlobalHeading className='typography-center' text='OUR LOCATION' />
          <DynamicSpace gs={30} />
          <GlobalParagraph
            fontSize={{ xs: '12px', md: '13px' }}
            letterSpacing="0.02em"
            className='typography-center'
            text='To Book Online and to see full information Contact Us:'
          />
          <DynamicSpace gs={55} />
          <GlobalHeading text='LONDON'  />
          <DynamicSpace gs={20} />



          <div style={{ display: 'flex', alignItems: 'center', marginBottom: '25px' }}>
          <FiberSmartRecordIcon sx={{ marginRight: '10px', fontSize: '16px' }} />
            <GlobalHeading text='LONDON  |  020 7916 1502' fontSize={{ xs: '12px', md: '12px' }} />
          </div>
          <GlobalParagraph
            fontSize={{ xs: '12px', md: '14px' }}
            letterSpacing="0.02em"
            text='180 Seven Sisters Road, Finsbury Park, London N7 7PX'
          />
          <DynamicSpace gs={45} />
          <DynamicDivider/>
          <DynamicSpace gs={30} />
          <GlobalParagraph
            fontSize={{ xs: '12px', md: '13px' }}
            letterSpacing="0.02em"
            className='typography-center'
            text='Masters Hair & Beauty offers Hairdressing, Hair Colour, Facials, Massage, Body Treatments, Hair Removal, Manicures, 
            Pedicures and Brow and Lash Services, as well as offering Skincare, Haircare and Wellbeing products to take home with you. 
            Our Salons are premium yet comfortable, with experts on hand to help you with everything you need.'
          />
        </div>

      </Container>
      <DynamicSpace xs={60} sm={60} />

    </section>
  );
};

export default OurLocations;
