import React from 'react';
import {  Container } from '@mui/material';
import ServicesContent from './Ourservices/ServicesContent';

const services: React.FC = () => {
  return (
    <section className='section-back'>
      <Container>
        <ServicesContent/>
      </Container>
      </section>
  )
};



export default services;