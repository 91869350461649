import React from 'react';
import GlobalHeading from '../styles/components/GlobalHeading';
import GlobalParagraph from '../styles/components/GlobalParagraph';
import { Container } from '@mui/material';
import DynamicSpace from '../styles/Helpers/DynamicSpace';
import sec2 from './images/landing.jpg';


const GiftCard: React.FC = () => {
  return (
    <section className='section-back'>
      <Container>
        <div className='content_panel'>
          <DynamicSpace gs={75} xs={50} sm={50}  />
          <GlobalHeading className='typography-center' text='MASTERS E-GIFT CARDS' />
          <DynamicSpace gs={30} />
          <GlobalParagraph
            fontSize={{ xs: '12px', md: '13px' }}
            letterSpacing="0.02em"
            className='typography-center'
            lineHeight='1.65'
            text='Treat yourself or someone special to the perfect gift from Master s Hair & Beauty. Buy a Gift Card Online Now.'
          />
          <DynamicSpace gs={40} />
          <img src={sec2} alt="Instantly Delivered" style={{ display: 'block', margin: '0 auto', maxWidth: '80%', maxHeight: '300px' }} />

          <DynamicSpace gs={40} />
          
          <GlobalHeading text='INSTANTLY DELIVERED AND AVAILABLE' />
          <DynamicSpace gs={20} />

          <GlobalParagraph
            fontSize={{ xs: '12px', md: '13px' }}
            lineHeight='1.65'
            letterSpacing="0.02em"
            text="Your Masters Gift Card will be emailed to you or the Recipient straight away - perfect for last-minute gifting emergencies or an impulsive thank you. The Masters Gift Card is instantly loaded into the systems of all Allertons Locations, meaning it can be used immediately."
          />
          <DynamicSpace gs={40} />

          <GlobalHeading text='FOR ALL IN-SALON SERVICES AND PRODUCTS' />
          <DynamicSpace gs={20} />

          <GlobalParagraph
            fontSize={{ xs: '12px', md: '13px' }}
            lineHeight='1.65'
            letterSpacing="0.02em"
            text="The Masters Gift Card can be used for any Allertons Service, from a pampering Massage, rejuvenating Facial, or their favourite Hair Colour. The Recipient can also use their Gift Card to buy their favourite Shampoo, Skincare Product or Candle - to anything we sell for them to take home."
          />
          <DynamicSpace gs={40} />

          <GlobalHeading text='ENVIRONMENTALLY SUSTAINABLE' />
          <DynamicSpace gs={20} />

          <GlobalParagraph
            fontSize={{ xs: '12px', md: '13px' }}
            lineHeight='1.65'
            letterSpacing="0.02em"
            text="Masters believes in Sustainability, which is why we no longer sell physical Gift Cards."
          />
          <DynamicSpace gs={40} />

          <GlobalHeading text='BUY ONLINE NOW' />
          <DynamicSpace gs={20} />

          <GlobalParagraph
            fontSize={{ xs: '12px', md: '13px' }}
            lineHeight='1.65'
            letterSpacing="0.02em"
            text="It's quick and easy - Buy a Gift Card Online Now."
          />
          <DynamicSpace gs={95} xs={60} sm={60}  />

        </div>
      </Container>
    </section>
  );
};

export default GiftCard;
