import React from 'react';
import FAQsAccordion from './faqsAccordion';

const FaqsContent: React.FC = () => {
  // Define your accordion items data
  const accordionItems = [
    {
      title: "Appointments",
      content: [
        { question: "Book an Appointment", 
        answerText: "You can Book Online <a href='/booking'>Here.</a>. If you prefer, you can find the telephone number of our Salon Here <a href='/contact-us'>Here.</a>." 
        },
        { question: "Appointment Policies", answerText: "Masters has a number of Salon Policies to ensure your comfort and safety before, during and after your visit. We take our responsibilities extremely seriously, and want to ensure Masters is a safe, friendly, professional and convenient environment for all of our guests. Please read them all <a href='/salonpolicies'>Here.</a>" },
      ],
    },
    {
        title: "Hair Colour",
        content: [
          { question: "The Importance of Hair Colour Consultations", 
          answerText: "New Clients (and existing Clients who have not had their Hair Colour done with us within 6 months or are looking for something different) need a Free Consultation before booking. Whichever Hair Colour appointment you choose, the process, result and price will be unique to you. The result of your appointment depends on a number of factors, including: \nHair Length\nHair Thickness\nHair Condition\nCurrent Hair Colour and Buildup\nDesired Hair Colour \n \n At a Free Masters Hair Colour Consultation, your Styist will discuss all of these with you to ensure you achieve the result you want - in some circumstances, your Stylist may need to conduct a 'Strand Test', which is when they take a small amount of hair to test, to ensure your desires result is possible. \n \n In addition, we will perform a Hair Colour Allergy Test to ensure your safety and comfort, which needs to be conducted 48 or more hours in advance. \n \n You can book a Free Hair Colour Consultation Online <a href='/booking'>Here.</a>."}
          ,{ question: "Children's Hair Colour", 
          answerText: "We are unable to provide Hair Colour services for anyone under the age of 16. This is a legal requirement." 
          },
        ],
        },
      {
        title: "Online Shopping",
        content: [
          { question: "Authenticity", 
          answerText: "Masters are an official authorised stockist of all the brands we carry, so you can buy with confidence." 
          },
          { question: "Shipping", 
          answerText: "Yes, we ship all over the world. Shipping costs will apply, and will be added at checkout. We run discounts and promotions all year, so stay tuned for exclusive deals." 
          },
          { question: "Returns", 
          answerText: "We always aim for make sure our customers love our products, but if you do need to return an order, we're happy to help. Just email us directly and we'll take you through the process." 
          }
        ],
      },
  ];

  return (
    <div>
      {/* Use the AdvancedAccordion component and pass the accordion items */}
      <FAQsAccordion accordionItems={accordionItems} />
    </div>
  );
};

export default FaqsContent;
