import React from 'react';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';

interface AnchorProps {
  text: string;
  className?: string;
  variant?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6';
  fontSize?: { xs?: string; sm?: string; md?: string; lg?: string; xl?: string };
  letterSpacing?: string;
  fontWeight?: number;
  fontFamily?: string;
  lineHeight?: { xs?: string; sm?: string; md?: string; lg?: string; xl?: string };
  href?: string;
  underline?: boolean; // Add prop to control underlining
  color?: string;
  textAlign ?: 'left' | 'right' | 'center' | 'justify';
}

const GlobalAnchor: React.FC<AnchorProps> = ({
  text,
  className,
  variant = 'h1',
  fontSize = { xs: '18px', md: '16px' },
  letterSpacing = '0.02em',
  fontWeight = 400,
  fontFamily = 'Futura, sans-serif',
  lineHeight = { xs: '1.3' },
  href,
  underline = true, // Default value for underline
  color = 'inherit',
  textAlign  = 'center',
  
  ...otherProps
}) => {
  const renderLink = href ? (
    <Link href={href} style={{ textDecoration: underline ? 'underline' : 'none', color: 'inherit', fontFamily: 'Futura, sans-serif' }}>
      {text}
    </Link>
  ) : (
    text
  );

  return (
    <Typography
      variant={variant}
      sx={{
        fontSize,
        letterSpacing,
        fontWeight,
        fontFamily,
        lineHeight,
        color,
        textAlign 
      }}
      className={className}
      {...otherProps}
    >
      {renderLink}
    </Typography>
  );
};

export default GlobalAnchor;
