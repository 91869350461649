import React from "react";
import { Container, Grid, TextField, Button } from "@mui/material";
import GlobalHeading from "../styles/components/GlobalHeading";
import DynamicSpace from "../styles/Helpers/DynamicSpace";

const ContactForm: React.FC = () => {
  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    // Handle form submission here
  };

  return (
    <>
      <GlobalHeading className="typography-center" text="CONTACT US" />
      <DynamicSpace gs={30} />
      <form onSubmit={handleSubmit}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <TextField
              required
              label="Your Name"
              fullWidth
              variant="outlined"
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              required
              label="Your Email"
              fullWidth
              variant="outlined"
            />
          </Grid>
          <Grid item xs={12} md={12}>
            <TextField
              label="Your Telephone Number (optional)"
              fullWidth
              variant="outlined"
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              required
              label="Your Message"
              multiline
              rows={4}
              fullWidth
              variant="outlined"
            />
          </Grid>
          <Grid item xs={12}>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              fullWidth
              sx={{
                borderRadius: "5px",
                display: "flex",
                alignItems: "center",
                backgroundColor: "#468bbf",
                justifyContent: "center",
                position: "relative",
                padding: "8px 0px",
              }}
            >
              Send Message
            </Button>
          </Grid>
        </Grid>
      </form>
      <DynamicSpace gs={90} xs={10} sm={10}/>
    </>
  );
};

export default ContactForm;
