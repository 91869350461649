import React from 'react';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import StarBorderIcon from '@mui/icons-material/StarBorder';
import GlobalHeading from '../../styles/components/GlobalHeading';
import DynamicSpace from '../../styles/Helpers/DynamicSpace';

const SpecialOffers: React.FC = () => {
  return (
    <><GlobalHeading
          text="Special Offers"
          variant="h3"
          fontSize={{ xs: "14px", md: "16px" }}
          fontWeight={600}
          letterSpacing="0.04em" />
          <DynamicSpace gs={10} />

          
          <Box
              sx={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: '10px',
                  boxShadow: 'rgba(99, 99, 99, 0.2) 0px 2px 8px 0px',
                  borderRadius: '10px',
                  padding: '20px'
              }}
          >
              {/* Icon */}
              <IconButton>
                  <StarBorderIcon sx={{color: '#337ab7'}} />
              </IconButton>

              {/* Content */}
              <div>
                  <GlobalHeading
                      text="View all special offers"
                      color={"#337ab7"}
                      variant="h3"
                      fontSize={{ xs: "12px", md: "14px" }}
                      fontWeight={600}
                      letterSpacing="0.04em" />
                      <DynamicSpace gs={7} />
                      <GlobalHeading
                      text="Don't miss our special deals."
                      variant="h3"
                      fontSize={{ xs: "11px", md: "13px" }}
                      fontWeight={400}
                      letterSpacing="0.04em" />
                      <DynamicSpace gs={5} />

              </div>
          </Box></>
  );
};

export default SpecialOffers;
