import React from 'react';
import Typography, { TypographyProps } from '@mui/material/Typography';

interface HeadingProps extends TypographyProps {
  text: string;
  className?: string;
  variant?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6';
  fontSize?: { xs?: string; sm?: string; md?: string; lg?: string; xl?: string };
  letterSpacing?: string;
  fontWeight?: number;
  fontFamily?: string;
  lineHeight?: { xs?: string; sm?: string; md?: string; lg?: string; xl?: string };
}

const GlobalHeading: React.FC<HeadingProps> = ({
  text,
  className,
  variant = 'h1',
  fontSize = { xs: '18px', md: '22px' },
  letterSpacing = '0.2em',
  fontWeight = 400,
  fontFamily = 'Futura, sans-serif',
  lineHeight = { xs: '1.3' },
  ...otherProps
}) => {
  return (
    <Typography
      variant={variant}
      sx={{
        fontSize,
        letterSpacing,
        fontWeight,
        fontFamily,
        lineHeight,
      }}
      className={className}
      {...otherProps}
    >
      {text}
    </Typography>
  );
};

export default GlobalHeading;