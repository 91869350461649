import React, { useRef, useEffect, useState } from 'react';
import { motion } from 'framer-motion';
import AdvancedAccordion from './Ourservices/Accordion';

const AnotherPage: React.FC<{}> = () => {
  const textRef = useRef<HTMLParagraphElement>(null);
  const [textHeight, setTextHeight] = useState<number>(0);
  const [animationComplete, setAnimationComplete] = useState<boolean>(false);

  useEffect(() => {
    if (textRef.current) {
      setTextHeight(textRef.current.clientHeight);
    }
  }, [textRef]);

  const onAnimationComplete = () => {
    setAnimationComplete(true);
  };

  return (
    <>
    
    {/* <div style={{ position: 'relative', width: 'max-content' }}>
      <h1>Another Page</h1>
      <p ref={textRef} style={{ position: 'relative' }}>
        Welcome to Another Page!
        <motion.div
          style={{
            position: 'absolute',
            left: 0,
            top: 0,
            width: '100%',
            height: textHeight,
            backgroundColor: 'black',
          }}
          initial={{ width: 0 }}
          animate={animationComplete ? { width: 0 } : { width: '100%' }}
          transition={{ duration: 0.4, onComplete: onAnimationComplete }} />
      </p>
    </div> */}
    
    </>
  );
};

export default AnotherPage;
