import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface AuthState {
  isLoggedIn: boolean;
  loading: boolean;
}

const initialState: AuthState = {
  isLoggedIn: false,
  loading: true,
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setLoggedIn(state, action: PayloadAction<boolean>) {
      state.isLoggedIn = action.payload;
      state.loading = false;
    },
  },
});

export const { setLoggedIn } = authSlice.actions;

export default authSlice.reducer;
