import React from 'react';
import {  Container } from '@mui/material';
import FaqsContent from './FAQs/faqsContent';

const Faqs: React.FC = () => {
  return (
    <section className='section-back'>
      <Container>
        <FaqsContent/>
      </Container>
      </section>
  )
};



export default Faqs;