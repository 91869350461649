import React from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom';
import Homepage from './pages/Homepage/Landingsection';
import Header from './components/header';
import AnotherPage from './pages/AnotherPage';
import Footer from './components/Footer';
import BookingForm from './pages/Booking/BookingForm';
import BookingHeader from './components/BookingHeader';
import NotFoundPage from './pages/NotFoundPage';
import store from './stateManagers/store';
import SignIn from './pages/Booking/signIn';
import OurLocations from './pages/OurLocations';
import GiftCard from './pages/GiftCard';
import SalonPolicies from './pages/Salonpolicies';
import ContactUs from './pages/ContactUs';
import Services from './pages/services';
import Faqs from './pages/Faqs';


const AppRoutes: React.FC = () => {
  const location = useLocation();
  const isBookingFormRoute = location.pathname === '/Booking';
  const issignInRoute = location.pathname === '/signin';

  return (
    <Provider store={store}>
    <div className="app-container">
      <div className="content">
        {!isBookingFormRoute && !issignInRoute && <Header />} {/* Add condition for SignIn route */}
        {isBookingFormRoute && <BookingHeader />}
        {issignInRoute && <BookingHeader />}
        <Routes>
          <Route path="/" element={<Homepage />} />
          <Route path="/signin" element={<SignIn />} />
          <Route path="/anotherpage" element={<AnotherPage />} />
          <Route path="/Booking" element={<BookingForm />} />
          <Route path="*" element={<NotFoundPage />} />
          <Route path="our-locations" element={<OurLocations />} />
          <Route path="gift-cards" element={<GiftCard />} />
          <Route path="salonpolicies" element={<SalonPolicies />} />
          <Route path="contact-us" element={<ContactUs />} />
          <Route path="services" element={<Services />} />
          <Route path="faqs" element={<Faqs />} />
        </Routes>
      </div>
      <Footer />
    </div>
    </Provider>
  );
};

function App() {
  return (
    <Router>
      <AppRoutes />
    </Router>
  );
}

export default App;