import React from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import { Link } from 'react-router-dom';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import GlobalAnchor from '../styles/components/GlobalAnchor';

const theme = createTheme({
 typography: {
   fontFamily: [
     'Futura',
     'sans-serif',
   ].join(','),
 },

});

const BookingHeader: React.FC = () => {
 return (
   <ThemeProvider theme={theme}>
     <AppBar position="static" sx={{ backgroundColor: 'white', padding: '0px 24px', boxShadow: '0px 2px 4px -1px rgb(0 0 0 / 0%), 0px 4px 5px 0px rgb(0 0 0 / 0%), 0px 1px 10px 0px rgb(121 127 132 / 34%)' }}>
       <Toolbar sx={{ display: 'flex', justifyContent: 'space-between' }}>
       <GlobalAnchor
                text="MASTER'S HAIR & BEAUTY"
                variant="h6"
                fontSize={{ xs: '12px', md: '16px' }}
                letterSpacing="0.2em"
                lineHeight={{ xs: '1.5', md: '1.5' }}
                color="black"
                href="/"
                textAlign='left'
                underline={false}
              />
         <div>
           <a href="*" style={{ textDecoration: 'none', color: 'black', marginRight: '16px', fontFamily: 'Futura, sans-serif', fontSize: '13px' }}>
             About Us
           </a>
           <a href="/booking" style={{ textDecoration: 'none', color: 'black', fontFamily: 'Futura, sans-serif' , fontSize: '13px' }}>
             Book an Appointment
           </a>
         </div>
       </Toolbar>
     </AppBar>
   </ThemeProvider>
 );
};

export default BookingHeader;