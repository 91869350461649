import React from 'react';
import { Grid, Typography, Card, CardHeader, CardMedia, CardContent } from '@mui/material';
import gift from '../images/gift.jpg'; // Import the image file
import GlobalHeading from '../../styles/components/GlobalHeading';
import DynamicSpace from '../../styles/Helpers/DynamicSpace';
import Heading2 from '../../styles/components/Heading2';

interface GiftCardProps {
  image: string;
  title: string;
  description: string;
}

const GiftCard: React.FC<GiftCardProps> = ({ image, title, description }) => {
  return (
    <Card sx={{ margin: 0, border: 'none', boxShadow: 'none' }}> {/* Removed border and box shadow */}
      <CardMedia
        component="img"
        height={(300 / 16) * 11} // Calculate the height for a 16:9 aspect ratio
        image={image}
        alt={title}
      />
      <CardHeader
        title={title}
        titleTypographyProps={{ variant: 'h5', fontFamily: 'Arial, sans-serif', fontSize: '18px' , letterSpacing: '.2em', paddingTop: '15px', paddingBottom: '0px' }} // Reduced paddingBottom
      />
      <CardContent style={{ paddingBottom: '15px' }}> {/* Reduced paddingBottom */}
        <Typography variant="body2" color="text.secondary" style={{ fontFamily: 'Futura, sans-serif', fontSize: '14px' , lineHeight: '1.65', paddingBottom: '15px' }}>
          {description}
        </Typography>
        <a href="#" style={{ textDecoration: 'underline', color: '#1c1b1b', fontSize: '14px' }}>Read More</a>
      </CardContent>
    </Card>
  );
};

const SixthSection: React.FC = () => {
  return (
    <section style={{
      position: 'relative',
      backgroundColor: 'white',
      padding: '75px 10% 115px 10%',
      
    }}>
        <Grid style={{textAlign: 'center'}}>
            <GlobalHeading text="MASTER'S HAIR & BEAUTY" variant="h2" fontSize={{ xs: '12px', md: '12px' }} />
            <DynamicSpace gs={16} />
            <Heading2 text="NEWS" />
            <DynamicSpace gs={50} />
        </Grid>
      <Grid container spacing={3}> {/* Added spacing attribute */}
        
        <Grid item xs={12} sm={4} style={{ display: 'flex', justifyContent: 'center' }}>
          <GiftCard
            image={gift}
            title="MASTERS OFFERS AND EVENTS"
            description="2024 is Masters' 10th Anniversary year. To celebrate, we will be thanking our Clients with a series of promotions, month by month, up until our a..."
          />
        </Grid>
        <Grid item xs={12} sm={4} style={{ display: 'flex', justifyContent: 'center' }}>
          <GiftCard
            image={gift}
            title="MASTERS OFFERS AND EVENTS"
            description="2024 is Masters' 10th Anniversary year. To celebrate, we will be thanking our Clients with a series of promotions, month by month, up until our a..."
          />
        </Grid>
        <Grid item xs={12} sm={4} style={{ display: 'flex', justifyContent: 'center' }}>
          <GiftCard
            image={gift}
            title="MASTERS OFFERS AND EVENTS"
            description="2024 is Masters' 10th Anniversary year. To celebrate, we will be thanking our Clients with a series of promotions, month by month, up until our a..."
          />
        </Grid>
      </Grid>
    </section>
  );
};

export default SixthSection;
