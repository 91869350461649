import React from 'react';
import GlobalHeading from '../styles/components/GlobalHeading';
import GlobalParagraph from '../styles/components/GlobalParagraph';
import GlobalAnchor from '../styles/components/GlobalAnchor';
import { Container } from '@mui/material';
import DynamicSpace from '../styles/Helpers/DynamicSpace';
import sec2 from './images/section22.jpg';
import landing from './images/landing.jpg';
import DynamicDivider from '../styles/Helpers/DynamicDivider';

const SalonPolicies: React.FC = () => {
  return (
    <section className='section-back'>
      <Container>
        <div className='content_panel'>
          <DynamicSpace gs={75}  xs={50} sm={50} />
          <GlobalHeading className='typography-center' text='SALON POLICIES' />
          <DynamicSpace gs={30} />
          <GlobalParagraph
            fontSize={{ xs: '12px', md: '13px' }}
            letterSpacing="0.02em"
            className='typography-center'
            lineHeight='1.65'
            text='Masters has a number of Salon Policies to ensure your comfort and safety before, during and after your visit. We take our responsibilities extremely seriously, and want to ensure Masters is a safe, friendly, professional and convenient environment for all of our guests.'
          />
          <DynamicSpace gs={50} />
          <img src={sec2} alt="Instantly Delivered" style={{ display: 'block', margin: '0 auto', maxWidth: '80%', maxHeight: '300px' }} />
          <DynamicSpace gs={50} />
          <GlobalHeading text='AGE RESTRICTIONS' />
          <DynamicSpace gs={20} />
          <GlobalParagraph
            fontSize={{ xs: '12px', md: '13px' }}
            lineHeight='1.65'
            letterSpacing="0.02em"
            text='For your comfort and safety, certain services have a minimum age requirement. All guests 16 and under must have a Parent or Guardian on the premises for the duration of their stay.'
          />
          <DynamicSpace gs={20} />
          <ul className='policies_links'>
            <li >
                All Beauty Services (unless mentioned below), 16
            </li>
            <li>Hair Colour, 16</li>
            <li>Intimate Waxing, 18</li>
            <li>Microneedling, 18</li>
            <li>Chemical Peels, 18</li>
          </ul>
          <DynamicSpace gs={40} />
          <GlobalHeading text='AFTERCARE' />
          <DynamicSpace gs={20} />
          <GlobalParagraph
            fontSize={{ xs: '12px', md: '13px' }}
            lineHeight='1.65'
            letterSpacing="0.02em"
            text='We are required to give you information on aftercare after each applicable treatment, even if we have given you the information before.'
          />
          {/* Add more policy sections here */}
          <DynamicSpace gs={40} />
          <GlobalHeading text='MASTERS REWARDS' />
          <DynamicSpace gs={20} />
          <GlobalParagraph
            fontSize={{ xs: '12px', md: '13px' }}
            lineHeight='1.65'
            letterSpacing="0.02em"
            text='Rewards collected via MASTERS Rewards:'
          />
          <DynamicSpace gs={20} />
          <ul className='policies_links'>
            <li>
                Are accrued at a rate of 5% (5p for every £1 spent)
            </li>
            <li>
                Have no cash value
            </li>
            <li>
                Are non transferable
            </li>
            <li>
                Gained from leaving a review are once per Client, after we have verified the review has been posted to our public Google pages
            </li>
            <li>
                Are automatically redeemed via our software system by converted into a £10 Masters voucher, which is then emailed to you
            </li>
            <li>
                Cannot be used in conjunction with any other offer or discount
            </li>
          </ul>
          <DynamicSpace gs={40} />
          <GlobalHeading text='ANIMALS' />
          <DynamicSpace gs={20} />
          <GlobalParagraph
            fontSize={{ xs: '12px', md: '13px' }}
            lineHeight='1.65'
            letterSpacing="0.02em"
            text='Animals, excluding Guide Dogs, are not permitted.'
          />
          <DynamicSpace gs={40} />
          <GlobalHeading text='ARRIVING FOR YOUR APPOINTMENT' />
          <DynamicSpace gs={20} />
          <GlobalParagraph
            fontSize={{ xs: '12px', md: '13px' }}
            lineHeight='1.65'
            letterSpacing="0.02em"
            text='Please arrive for your appointment 5 to 10 minutes early to relax with a drink, and to complete any paperwork we may need you to. 
            If you arrive late for your appointment, we may not be able to accommodate you, and you may still be charged in full.'
          />
          <DynamicSpace gs={40} />
          <GlobalHeading text='APPOINTMENT AMENDMENT POLICY' />
          <DynamicSpace gs={20} />
          <GlobalParagraph
            fontSize={{ xs: '12px', md: '13px' }}
            lineHeight='1.65'
            letterSpacing="0.02em"
            text='We require 48 hours notice to amend or cancel any appointment. If less than this is given, you may be charged in full. 
            Where a deposit is taken for an appointment, the appointment cannot be moved to a later date or time without forfeiting the deposit. 
            Due to the increased delay in acknowledging and replying to emails and other online contact methods, we are unable to accept appointment 
            amendments or cancellations via any method other than by telephone call.'
          />
          <DynamicSpace gs={40} />
          <GlobalHeading text='CHILD POLICY' />
          <DynamicSpace gs={20} />
          <GlobalParagraph
            fontSize={{ xs: '12px', md: '13px' }}
            lineHeight='1.65'
            letterSpacing="0.02em"
            text='Any Children under the age of 16 must be supervised at all times.
             However, please note that Beauty Treatments are conducted in single occupancy private rooms,
              that children cannot enter, even if you are having a treatment.'
          />
          <DynamicSpace gs={40} />
          <GlobalHeading text='CONSULTATIONS & PATCH TESTS' />
          <DynamicSpace gs={20} />
          <GlobalParagraph
            fontSize={{ xs: '12px', md: '13px' }}
            lineHeight='1.65'
            letterSpacing="0.02em"
            text='To ensure your safety and comfort, we may require you to complete a Consultation Form before a treatment is performed.
             Certain services, including but not limited to Hair Colour and Eyelash Tinting, require a Free Consultation and Patch Test
              a minimum of 48 hours in advance of an appointment, and an additional Patch Test once every 6 months, or if your medical history changes.
               Appointments that require a Free Consultation and Patch Test cannot be booked until these are performed.'
          />
          <DynamicSpace gs={40} />
          <GlobalHeading text='CORRECTIONS' />
          <DynamicSpace gs={20} />
          <GlobalParagraph
            fontSize={{ xs: '12px', md: '13px' }}
            lineHeight='1.65'
            letterSpacing="0.02em"
            text='If for any reason you are unhappy with your service, please do not hesitate to let us know within 7 days,
             and we will happily rectify it free of charge. For corrections to be free of charge,
              they must be completed within 14 days of your original appointment, where Masters availability allows.
               Due to the nature of salon appointments and services, we are unable to provide refunds,
                but may, at our discretion, provide an Masters Gift Card for a value up to and including the amount paid for the original appointment.'
          />
          <DynamicSpace gs={40} />
          <GlobalHeading text='COURSES / PACKAGES OF MULTIPLE APPOINTMENTS' />
          <DynamicSpace gs={20} />
          <GlobalParagraph
            fontSize={{ xs: '12px', md: '13px' }}
            lineHeight='1.65'
            letterSpacing="0.02em"
            text='Cannot be refunded, and have no cash value.'
          />
          <DynamicSpace gs={40} />
          <GlobalHeading text='DEPOSITS' />
          <DynamicSpace gs={20} />
          <GlobalParagraph
            fontSize={{ xs: '12px', md: '13px' }}
            lineHeight='1.65'
            letterSpacing="0.02em"
            text='Deposits are non refundable and non transferable. Where a deposit is taken for an appointment, 
            the appointment cannot be moved to a later date or time without forfeiting the deposit.'
          />
          <DynamicSpace gs={40} />
          <GlobalHeading text='DISCOUNTS' />
          <DynamicSpace gs={20} />
          <GlobalParagraph
            fontSize={{ xs: '12px', md: '13px' }}
            lineHeight='1.65'
            letterSpacing="0.02em"
            text='Discounts are applied at Masters discretion, and can be withdrawn or modified at any time, without notice. 
            Any Discounts cannot be used in conjunction with any other offer or Discount, and are non transferable.'
          />
          <DynamicSpace gs={40} />
          <GlobalHeading text='DRUGS & ALCOHOL' />
          <DynamicSpace gs={20} />
          <GlobalParagraph
            fontSize={{ xs: '12px', md: '13px' }}
            lineHeight='1.65'
            letterSpacing="0.02em"
            text='We are unable to provide services for clients who are under the influence or drugs and/or alcohol.'
          />
          <DynamicSpace gs={40} />
          <GlobalHeading text='GIFT CARDS AND E-GIFT CARDS / VOUCHERS' />
          <DynamicSpace gs={20} />
          <GlobalParagraph
            fontSize={{ xs: '12px', md: '13px' }}
            lineHeight='1.65'
            letterSpacing="0.02em"
            text='Expire 12 months from the date of purchase. Gift Cards should be treated as cash, as lost or stolen Gift Cards cannot be replaced. Non-refundable.'
          />
          <DynamicSpace gs={40} />
          <GlobalHeading text='PREGNANCY MASSAGE' />
          <DynamicSpace gs={20} />
          <GlobalParagraph
            fontSize={{ xs: '12px', md: '13px' }}
            lineHeight='1.65'
            letterSpacing="0.02em"
            text='Before treatment clients must:'
          />
          <DynamicSpace gs={20} />
          <ul className='policies_links'>
            <li>
                have permission from their GP or Midwife
            </li>
            <li>
                be more than 12 weeks pregnant
            </li>
            <li>
                have had their first scan.
            </li>
          </ul>
          <DynamicSpace gs={20} />
          <GlobalParagraph
            fontSize={{ xs: '12px', md: '13px' }}
            lineHeight='1.65'
            letterSpacing="0.02em"
            text='During the massage, we are unable to massage the abdomen, pressure points, the sides of the ankles, or between your thumb and index finger.'
          />
          <DynamicSpace gs={40} />
          <GlobalHeading text='TREATMENT ROOMS' />
          <DynamicSpace gs={20} />
          <GlobalParagraph
            fontSize={{ xs: '12px', md: '13px' }}
            lineHeight='1.65'
            letterSpacing="0.02em"
            text='Are single occupancy. No one other than the client and Therapist will be in the room, including Groups, 
            Children and so on. We are unable to provide treatments in beauty rooms where a client needs to be accompanied.'
          />

          <DynamicSpace gs={40} />
          <DynamicDivider/>
          <DynamicSpace gs={40} />

          <GlobalHeading text='HOW TO BOOK YOUR APPOINTMENT' />
          <DynamicSpace gs={20} />
          <GlobalParagraph
            fontSize={{ xs: '12px', md: '13px' }}
            lineHeight='1.65'
            letterSpacing="0.02em"
            text='Each Masters Hair & Beauty Salon offers many different Treatments and Services,
             all tailored perfectly to the result or experience you are looking for:'
          />
          <DynamicSpace gs={20} />
          <ul className='policies_links'>
            <li>
                <a href="/">Our Salon</a>
            </li>
            <li>
                <a href="/">Our Services</a>
            </li>
            <li>
                <a href="/our-locations">Contact Us</a>   if you need further assistance.
            </li>
          </ul>
          <DynamicSpace gs={50} />
          <img src={landing} alt="Instantly Delivered" style={{ display: 'block', margin: '0 auto', maxWidth: '80%', maxHeight: '60%' }} />

          <DynamicSpace gs={50} />

          {/* Add more policy sections here */}
          {/* Continue adding more policy sections */}
        </div>
        <DynamicSpace gs={95}  xs={50} sm={50} />

      </Container>
    </section>
  );
};

export default SalonPolicies;
