import React, { useState } from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import { Link } from 'react-router-dom'; // Import Link from react-router-dom
import DrawerLinks from './drawerlinks';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import FacebookIcon from '@mui/icons-material/Facebook';
import TwitterIcon from '@mui/icons-material/Twitter';
import InstagramIcon from '@mui/icons-material/Instagram';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import GlobalAnchor from '../styles/components/GlobalAnchor';

const theme = createTheme({
  typography: {
    fontFamily: [
      'Futura', 
      'sans-serif',
    ].join(','),
  },
  components: {
    MuiTypography: {
      styleOverrides: {
        root: {
          fontWeight: 200,
          fontStyle: 'normal',
          fontSize: '10px',
          color: 'white',
          transition: 'color .2s ease-in-out',
          letterSpacing: '.2em',
          textTransform: 'uppercase',
          lineHeight: '24px',
          textAlign: 'center',
          fontFamily: 'Futura, sans-serif'
        },
      },
    },
  },
});

const SubHeader: React.FC = () => {
  return (
    <ThemeProvider theme={theme}>
      <div style={{ backgroundColor: '#76a1b1', padding: '10px 13px' }}>
        <Container maxWidth="md">
          <GlobalAnchor
                text="BOOK ONLINE | TAP HERE"
                variant="h6"
                fontSize={{ xs: '12px', md: '16px' }}
                letterSpacing="0.2em"
                lineHeight={{ xs: '1.5', md: '1.5' }}
                color="white"
                href="/booking"
                underline={false}
              />
        </Container>
      </div>
    </ThemeProvider>
  );
};

const Header: React.FC = () => {
    const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  
    const toggleDrawer = () => {
      setIsDrawerOpen(!isDrawerOpen);
    };

    const closeDrawer = () => {
        setIsDrawerOpen(false);
      };
  
    return (
      <div>
        <SubHeader />
        <AppBar position="static" sx={{ backgroundColor: 'white', boxShadow: '0px 2px 4px -1px rgb(0 0 0 / 0%), 0px 4px 5px 0px rgb(0 0 0 / 0%), 0px 1px 10px 0px rgb(121 127 132 / 34%)' }}>
          <Toolbar>
            <IconButton onClick={toggleDrawer} sx={{ mr: 2 }}>
              <MenuIcon />
            </IconButton>
            <Toolbar sx={{ flex: 1, display: 'flex', justifyContent: 'center', alignContent:'center' }}>
              <GlobalAnchor
                text="MASTER'S HAIR & BEAUTY"
                variant="h6"
                fontSize={{ xs: '12px', md: '16px' }}
                letterSpacing="0.2em"
                lineHeight={{ xs: '1.5', md: '1.5' }}
                color="black"
                href="/"
                underline={false}
              />
            </Toolbar>
            <div style={{ width: theme.spacing(7) }} />
          </Toolbar>
        </AppBar>
        <Drawer
          anchor="left"
          open={isDrawerOpen}
          onClose={toggleDrawer}
          sx={{ 
              '& .MuiDrawer-paper': { 
                width: '22vw', 
                minWidth: '200px',
                padding:'10px 15px', // Adjusted padding
                background: 'linear-gradient(to bottom, rgb(0 0 0 / 79%) 0%, rgb(0 0 0 / 89%) 100%)' // Background gradient
              } 
            }}
          >
          <div style={{ display: 'flex', justifyContent: 'flex-end', paddingBottom: '8px' }}> {/* Adjusted padding */}
              <IconButton onClick={toggleDrawer} sx={{ color: '#ffffffc7' }}>
                <CloseIcon />
              </IconButton>
          </div>
          <DrawerLinks href="/" text="Home" closeDrawer={closeDrawer} />
          <DrawerLinks href="/our-locations" text="Our Location" closeDrawer={closeDrawer} />
          <DrawerLinks href="/gift-cards" text="Gift Cards" closeDrawer={closeDrawer} />
          <DrawerLinks href="/" text="Search" closeDrawer={closeDrawer} />
          <div style={{ display: 'flex', alignItems: 'center', marginTop: 'auto', borderTop: '0.5px solid #ffffff75', paddingBottom:'10px'}}>



          <Select
      value="GBP" // Set the value according to the selected currency
    //   onChange={(event) => handleCurrencyChange(event.target.value)}
      sx={{ color: '#ffffffc7', minWidth: '80px', marginLeft: 'auto', borderRight: '0.5px solid #ffffff75', fontFamily: 'Futura, sans-serif', fontSize: '12px', letterSpacing: '.2em', fontWeight: '400' }}
    >
      <MenuItem value="GBP">GBP</MenuItem>
      <MenuItem value="USD">USD</MenuItem>
      <MenuItem value="CAD">CAD</MenuItem>
      {/* Add more currency options as needed */}
    </Select>
    <div style={{ display: 'flex', marginRight: 'auto', paddingLeft:'20px' }}>
      <IconButton href="/facebook"
      
      sx={{ color: '#ffffffc7' , fontSize: '10px' }}>
        <FacebookIcon />
      </IconButton>
      <IconButton href="/twitter" sx={{ color: '#ffffffc7' , fontSize: '20px' }}>
        <TwitterIcon />
      </IconButton>
      <IconButton href="/instagram" sx={{ color: '#ffffffc7' , fontSize: '20px' }}>
        <InstagramIcon />
      </IconButton>
    </div>
    
  </div>
        </Drawer>
      </div>
    );
};

export default Header;
