import React from 'react';
import { Divider } from '@mui/material';

interface CustomDividerProps {
  length?: string;
  position?: 'left' | 'right' | 'center';
  color?: string;
}

const DynamicDivider: React.FC<CustomDividerProps> = ({
  length = '100%', // Default length is 100%
  position = 'center', // Default position is center
  color = 'black', // Default color is black
}) => {
  return (
    <Divider
      variant="middle"
      orientation="horizontal"
      sx={{
        width: length,
        alignSelf: position,
        backgroundColor: color,
      }}
    />
  );
};

export default DynamicDivider;
