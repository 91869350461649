import React from 'react';
import { Link } from 'react-router-dom';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';

interface DrawerLinkProps {
  href: string;
  text: string;
  closeDrawer: () => void; // Function to close the drawer
}

const DrawerLinks: React.FC<DrawerLinkProps> = ({ href, text, closeDrawer }) => (
  <>
    <ListItem button component={Link} to={href} onClick={closeDrawer}>
      <ListItemText
        disableTypography
        primary={<Typography variant="body2" style={{ color: '#ffffffc7', fontSize: '12px', letterSpacing: '.2em', fontWeight: '400', fontFamily: 'Futura, sans-serif' }}>{text}</Typography>}
      />
    </ListItem>
    <Divider sx={{ margin: '8px 0', backgroundColor: '#ffffffc7' }} />
  </>
);

export default DrawerLinks;
