import React from 'react';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';

interface DynamicSpaceProps {
  gs?: number; // Global spacing value in pixels
  xs?: number; // Additional spacing value for extra-small devices in pixels
  sm?: number; // Additional spacing value for small devices in pixels
  md?: number; // Additional spacing value for medium devices in pixels
  lg?: number; // Additional spacing value for large devices in pixels
  xl?: number; // Additional spacing value for extra-large devices in pixels
}

const DynamicSpace: React.FC<DynamicSpaceProps> = ({ gs, xs, sm, md, lg, xl }) => {
  const theme = useTheme();

  // Generate styles based on visibility props for each device size
  const styles: React.CSSProperties = {
    ...(gs !== undefined && { marginTop: `${gs}px` }),
    ...(xs !== undefined && { marginTop: `${xs}px` }), // Corrected xs usage
    ...(sm !== undefined && {
      [`@media (min-width: ${theme.breakpoints.values.sm}px)`]: {
        marginTop: `${sm}px`,
      },
    }),
    ...(md !== undefined && {
      [`@media (min-width: ${theme.breakpoints.values.md}px)`]: {
        marginTop: `${md}px`,
      },
    }),
    ...(lg !== undefined && {
      [`@media (min-width: ${theme.breakpoints.values.lg}px)`]: {
        marginTop: `${lg}px`,
      },
    }),
    ...(xl !== undefined && {
      [`@media (min-width: ${theme.breakpoints.values.xl}px)`]: {
        marginTop: `${xl}px`,
      },
    }),
  };

  return <Box sx={styles} />;
};

export default DynamicSpace;
